import { AnyAction } from 'redux';
import { initializeStore, SeamlessConnection } from '@seamless/store';
import {
  CTAData,
  ConnectionVehicleDataDispatchers,
  ConnectionVehicleDataState,
  ConnectionVehicleDataPrice,
} from './types';
import { CTAKeys, Actions } from './enums';

export const connectionName = 'OWC_VEHICLE_DATA';

const initialState: ConnectionVehicleDataState = {
  modelSeries: '',
  engineConcept: '',
  subBrands: [],
};

class VehicleDataConnection extends SeamlessConnection<ConnectionVehicleDataDispatchers, ConnectionVehicleDataState> {
  constructor() {
    super(connectionName);
  }

  protected get initialState() {
    const vehicleData = window.aemNamespace?.vehicleData || window.top?.aemNamespace?.vehicleData || {};
    return {
      ...initialState,
      ...vehicleData,
    };
  }

  protected getReducer() {
    return (state = initialState, action: AnyAction) => {
      switch (action.type) {
        case this.getActionType(Actions.UpdateCta):
          return {
            ...state,
            cta: {
              ...state.cta,
              ...action.payload,
            },
          };
        case this.getActionType(Actions.UpdatePrice):
          return {
            ...state,
            price: {
              ...state.price,
              ...action.payload,
            },
          };
        case this.getActionType(Actions.UpdateConfiguration):
          return {
            ...state,
            configurationId: action.payload,
          };
        default:
          return state;
      }
    };
  }

  protected getPublicDispatchers() {
    return {
      setCta: (data: CTAData) => this.getAction(Actions.UpdateCta, data),
      setPrice: (data: ConnectionVehicleDataPrice) => this.getAction(Actions.UpdatePrice, data),
      setConfigurationId: (data: string) => this.getAction(Actions.UpdateConfiguration, data),
    };
  }
}

export const connection = new VehicleDataConnection();

export const addConnectionVehicleData = async (): Promise<void> => {
  const store = initializeStore();
  return await store.addConnection(connection);
};

export const getConnectionVehicleDataDispatchers = async (): Promise<ConnectionVehicleDataDispatchers> => {
  const store = initializeStore();
  return await store.getConnectionDispatchers<ConnectionVehicleDataDispatchers>(connectionName);
};

export const isWithinVehicleContext = (): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    const store = initializeStore();
    store.once(connectionName, (state) => resolve(Boolean(state.modelSeries)));
  });

export { ConnectionVehicleDataState, CTAData, CTAKeys, ConnectionVehicleDataPrice };
