import { ConnectionVehicleDataState } from './connections/vehicle-data/types';
import { addConnectionVehicleData } from './connections/vehicle-data';
import { initializeLogger } from '@seamless/logger';
import { SERVICE_NAMESPACE } from './consts';

declare const VERSION: string;
export const version = VERSION;
const logger = initializeLogger(SERVICE_NAMESPACE);

declare global {
  interface Window {
    aemNamespace: AemNamespace;
  }
  interface AemNamespace {
    vehicleData: ConnectionVehicleDataState;
  }
}

(async () => {
  await addConnectionVehicleData();
  logger.log('Initialised store connection');
})();

export * from './connections/vehicle-data';
