export enum CTAKeys {
  Primary = 'primary',
  Secondary = 'secondary',
  Shop = 'shop',
  Configurator = 'configurator',
  About = 'about',
  Enquiry = 'enquiry',
  Informed = 'informed',
}

export enum Actions {
  UpdateCta = 'UPDATE_CTA',
  UpdatePrice = 'UPDATE_PRICE',
  UpdateConfiguration = 'UPDATE_CONFIGURATION',
}
